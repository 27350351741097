<template>
    <div>
        <v-card  :loading="isLoading">

            <v-card-text>
                <v-select
                    v-if="!opts"
                    ref="AliasSelect"
                    v-model="suggestedTag"
                    :items="options"
                    label="Select Alias"
                    :rules="[validations.required()]"
                    @keyup.native.enter="accept"
                ></v-select>
                <ComboBox
                    v-if="opts"
                    ref="AliasSelect"
                    v-model="suggestedTag"
                    :items="options"
                    label="Select Alias"
                    :rules="[validations.required()]"
                    @keyup.native.enter="accept"
                />
                <v-checkbox
                    v-show="false"
                    v-model="matched"
                    :rules="[validations.required()]"
                ></v-checkbox>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" text @click="decline">
                    Decline and Save as New
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="accept">
                    Accept
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar
            v-model="snack"
            :timeout="6000"
            :color="errorMessage ? 'error' : 'success'"
        >
            {{ errorMessage || "Success" }}
            <v-btn text @click="snack = false">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import ComboBox from "@/views/partials/form/ComboBox.vue";
import validations from "@/views/partials/form/Validations.vue";
import { SUGGEST_TAGS } from "@/store/vendorOffer.module";

export default {
    components: { ComboBox },
    props: ["tagCategory", "tagValue","select","opts"],
    data() {
        return {
            suggestedTag: null,
            isLoading: false,
            snack: false,
            errorMessage: null,
            validations: validations,
            matched: false,
            options: []
        };
    },
    mounted() {
        this.suggest();
    },
    watch: {
        tagCategory() {
            this.suggest();
        }
    },
    methods: {
        suggest() {
            if(this.opts){
                this.options = this.opts;
                this.suggestedTag = this.tagValue;
                return true;
            }
            this.isLoading = true;
            var params = {
                tags: [
                    {
                        category: this.tagCategory,
                        value: this.tagValue
                    }
                ]
            };

            this.$store
                .dispatch(SUGGEST_TAGS, params)
                .then(tags => {
                    let tag = tags[0];
                    this.options = tag.options || [tag.formatted_value];
                    this.suggestedTag = tag.formatted_value;
                    if (typeof tag.options === "undefined") {
                        this.accept();
                    }
                })
                .catch(e => {
                    console.error(e);
                     this.handleException(e)
                })
                .finally(() => {
                    this.isLoading = false;
                    if (this.$refs.AliasSelect) {
                        const input = this.$refs.AliasSelect.$el.querySelector(
                            "input"
                        );
                        input.focus();
                    }
                });
        },
        accept() {
            this.$emit("match", this.suggestedTag);
            this.matched = true;
        },
        decline() {
            this.options.push(this.tagValue);
            this.suggestedTag = this.tagValue;

            this.$emit("match", this.tagValue);
            this.matched = true;
        }
    }
};
</script>
