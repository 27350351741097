<template>
  <div>
    <v-card :disabled="isProcessing" :loading="isProcessing">
      <template v-slot:progress>
        <v-progress-linear
          absolute
          color="success"
          height="4"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-tabs
        v-model="tab"
        background-color="deep-purple accent-4"
        left
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          <v-toolbar-title>
            <slot name="title"></slot>
          </v-toolbar-title>
        </v-tab>
        <v-spacer></v-spacer>

        <div class="buttons-tab"></div>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <template v-slot:progress>
              <v-progress-linear
                absolute
                color="success"
                height="4"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-card-text>
              <v-form
                @submit.prevent
                ref="requestForm"
                v-model="validRequestData"
              >
                <v-row>
                  <v-col cols="12" sm="4">
                    <keep-alive>
                      <TPContactsAutocomplete
                        v-model="requestData.company_contact_id"
                        @processing="handleProcessing"
                        @processed="handleProcessed"
                        @input="contactChange"
                      ></TPContactsAutocomplete>
                    </keep-alive>
                  </v-col>
                  <template v-for="category in tagCategories">
                        <v-col
                            v-if="fields.showCategories.includes(category.id)"
                            cols="12"
                            sm="4"
                            :key="category.id"
                        >
                            <v-text-field
                                v-if="category.tag_category_type !== 'select' && category.tag_category_type !== 'autocomplete' && !category.opts"
                                v-model="requestData.tags[category.name]"
                                :rules="validations.getRuleFunctions(category,true)"
                                :label="category.name"
                                :placeholder="category.name"
                                @change="setChanges($event,category)"
                                prepend-icon="mdi-tag"
                                  @input="restrictChange($event)"
                            ></v-text-field>
                            <v-autocomplete
                                v-if="category.tag_category_type === 'select'"
                                v-model="requestData.tags[category.name]"
                                :items="categories[category.name]"
                                :label="category.name"
                                :rules="validations.getRuleFunctions(category)"
                                :placeholder="category.name"
                                prepend-icon="mdi-tag"
                                @input="restrictChange($event)"
                            ></v-autocomplete>
                            <ComboBox
                                v-if="category.tag_category_type === 'autocomplete'"
                                :ref="category.name"
                                v-model="requestData.tags[category.name]"
                                :items="categories[category.name]"
                                :label="category.name"
                                :rules="validations.getRuleFunctions(category)"
                                :placeholder="category.name"
                                prepend-icon="mdi-tag"
                                  @keyup.native.enter="accept($event,category.name)"
                                @input="restrictChange($event)"
                            />
                            <v-autocomplete
                                v-if="category.tag_category_type === 'text' && category.opts"
                                v-model="requestData.tags[category.name]"
                                :items="getOpts(category.opts)"
                                :label="category.name"
                                :rules="validations.getRuleFunctions(category)"
                                :placeholder="category.name"
                                prepend-icon="mdi-tag"
                                @input="restrictChange($event)"
                            ></v-autocomplete>
                            <ComboBox
                                v-else-if="category.opts"
                                :ref="category.name"
                                v-model="requestData.tags[category.name]"
                                :items="getOpts(category.opts)"
                                :label="category.name"
                                :rules="validations.getRuleFunctions(category)"
                                :placeholder="category.name"
                                prepend-icon="mdi-tag"
                                  @keyup.native.enter="accept($event,category.name)"
                                @input="restrictChange($event)"
                            />
                        </v-col>
                  </template>
                    <template v-if="showAdditional">
                        <template v-for="category in tagCategories">
                                    <v-col
                                        v-if="fields.expandCategories.includes(category.id)"
                                        cols="12"
                                        sm="4"
                                        :key="category.id"
                                    >
                                        <v-text-field
                                            v-if="category.tag_category_type !== 'select' && category.tag_category_type !== 'autocomplete' && !category.opts"
                                            v-model="requestData.tags[category.name]"
                                            :label="category.name"
                                            :placeholder="category.name"
                                            @change="setChanges($event,category)"
                                            @input="restrictChange($event)"
                                            prepend-icon="mdi-tag"
                                        ></v-text-field>
                                        <v-autocomplete
                                            v-if="category.tag_category_type === 'select'"
                                            v-model="requestData.tags[category.name]"
                                            :items="categories[category.name]"
                                            :label="category.name"
                                            :placeholder="category.name"
                                            prepend-icon="mdi-tag"
                                            @input="restrictChange($event)"
                                        ></v-autocomplete>
                                        <ComboBox
                                            v-if="category.tag_category_type === 'autocomplete'"
                                            :ref="category.name"
                                            v-model="requestData.tags[category.name]"
                                            :items="categories[category.name]"
                                            :label="category.name"
                                            :placeholder="category.name"
                                            prepend-icon="mdi-tag"
                                            @keyup.native.enter="accept($event,category.name)"
                                            @input="restrictChange($event)"
                                        />
                                    </v-col>
                            </template>
                        </template>

                  <v-col cols="12" sm="4">
                    <TPDatePicker
                      label="Date"
                      v-model="requestData.date"
                      :rules="[validations.required()]"
                      :key="formKey"
                    ></TPDatePicker>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <TPDatePicker
                      label="Expire Date"
                      v-model="requestData.expire_date"
                      :rules="[validations.required()]"
                      :key="formKey"
                    ></TPDatePicker>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      name="input-7-1"
                      label="Notes"
                      prepend-icon="mdi-text"
                      :disabled="isProcessing"
                      v-model="requestData.notes"
                     @input="restrictChange($event)"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="info" v-on:click="toggleAdditional()">{{textShowAdditional}}</v-btn>
              <v-btn color="primary" v-on:click="store()">Store</v-btn>
              <v-btn
                v-if="$route.params.id"
                color="success"
                v-on:click="update()"
                >Update</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
      <TPConfirm
            v-model="showConfirmSet"
            content="Do you want to overwrite the current offer data with last entered for this contact?"
            @confirm="setLatestContactOfferData"
        ></TPConfirm>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { SEARCH_COMPANY_CONTACTS } from "@/store/companyContact.module";
import {
  GET_QUICK_OFFER,
  STORE_QUICK_OFFER,
  UPDATE_QUICK_OFFER,
} from "@/store/quickOffer.module";
import { SHOW_SNACK } from "@/store/snack.module";
import validations from "@/views/partials/form/Validations.vue";
import ComboBox from "@/views/partials/form/ComboBox.vue";
import TPDatePicker from "@/views/partials/form/DatePicker.vue";
import TPContactsAutocomplete from "@/views/tagProcessor/Input/ContactsAutocomplete.vue";
import TPTagSuggest from "@/views/tagProcessor/Output/Table/TagSuggest.vue";
import { SEARCH_TAG_CATEGORIES } from "@/store/tagCategory.module";
import { SUGGEST_TAGS } from "@/store/vendorOffer.module";
import TPConfirm from "@/views/partials/dialogs/Confirm.vue";

export default {
  components: {
    TPDatePicker,
    TPContactsAutocomplete,
    TPTagSuggest,
    TPConfirm,
    ComboBox
  },
  props: [],

    beforeDestroy: function () {
        this.requestLoaded = false;
        this.categories = {};
        this.formKey+=1;
        this.setLastData = true;
        this.setInitialRequestData();
        this.$refs.requestForm.resetValidation();
    },
  mounted() {

    this.$store.dispatch(SEARCH_TAG_CATEGORIES,{type:1})
    .then(response => {
            this.tagCategories = response;
            this.tagCategories.forEach(x => {
                if(x.validation_rules.length > 0){
                    this.fields.showCategories.push(x.id);
                  this.fields.expandCategories = this.fields.expandCategories.filter(c => c !== x.id);
                }
            })
            this.getOptions();
    })

    if (this.$route.params.id) {
      this.loadQuickOffer();
    } else {
      this.requestLoaded = true;
      this.categories = {};
      this.formKey+=1;
      this.setLastData = true;
      this.setInitialRequestData();
      this.$refs.requestForm.resetValidation();
    }
  },
  data() {
    return {
      validations: validations,
      validRequestData: false,
      isProcessing: false,
      requestData: {},
      formKey: 0,
      requestLoaded: false,
      tagValue: "",
      tab: "tab-1",
      isSelect: true,
      categories:{},
      showAdditional:false,
      textShowAdditional:'Show Additional',
      setLastData:true,
      showConfirmSet:false,
      tagCategories:[],
      latestData:{},
      fields: {
          showCategories:[1,2,3,5,10],
          expandCategories:[4,6,7,8,9,11,12,13,14,15,16]
      }
    };
  },
  methods: {
    loadQuickOffer() {
      this.$store
        .dispatch(GET_QUICK_OFFER, this.$route.params.id)
        .then((request) => {
          this.$store
            .dispatch(SEARCH_COMPANY_CONTACTS, request.company_contact_id)
            .then(() => {
              this.requestData = request;
              this.$refs.requestForm.resetValidation();
              this.requestLoaded = true;
            })
            .finally(() => {
              this.isProcessing = false;
            });
        });
    },
    setInitialRequestData() {
      this.requestData = {
        company_contact_id: null,
        date: moment().format("YYYY-MM-DD"),
        expire_date: moment().add(7, "days").format("YYYY-MM-DD"),
        notes: null,
        tags:{UnitsPrice:'kg',Currency:'eur',UnitsQty:'kg',Qty:20000,Temp:'frozen',Incoterms:'exw'}
      };
    },
    process(type) {
      this.$refs.requestForm.validate();

      if (!this.validRequestData) {
        return;
      }

      this.resetLastChecked();

      var dispatchUrl =
        type === "store" ? STORE_QUICK_OFFER : UPDATE_QUICK_OFFER;
      var params =
        type === "store"
          ? this.requestData
          : {
              id: this.$route.params.id,
              params: this.requestData,
            };

      this.$store
        .dispatch(dispatchUrl, params)
        .then(() => {
          this.$store.dispatch(SHOW_SNACK, {
            message: "Request stored successfuly.",
          });
          if (type === "store") {
            if (this.$route.params.id) {
              this.$router.push({
                name: "list-requests",
              });
            } else {
              this.setInitialRequestData();
              this.$refs.requestForm.resetValidation();
            }
          }

          this.$emit("processed");
        })
        .catch((response) => {
            this.handleException(response)
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
    store() {
      this.process("store");
    },
    update() {
      this.process("update");
    },
    back() {
      this.$router.push({ name: "list-requests" });
    },
    handleProcessing() {
      this.isProcessing = true;
    },
    handleProcessed() {
      this.isProcessing = false;
    },
    selectSuggestedTag(data, tag) {
      data.suggestedTag = tag;
      const dataTypeName = data.type.name;
      data.type.name = null;
      data.type.name = dataTypeName;
    },
    getOptions(){
          let tags = [];
        this.tagCategories.forEach(category =>{
            if(category.tag_category_type === 'select' || category.tag_category_type === 'autocomplete'){
                tags.push({category:category.name,value:''});
            }
        })

        this.$store.dispatch(SUGGEST_TAGS, {tags:tags})
            .then(tags => {
                tags.forEach(tag => {
                    this.categories[tag.category] = tag.options;
                    this.formKey++;
                })
            })
            .catch(e => {
                console.error(e);
                  this.handleException(e)
            });

    },
    resetLastChecked() {
            this.filteredCount = null;
            this.lastCheckedCount = null;
            this.lastChecked = null;
    },
    toggleAdditional(){
        this.showAdditional = !this.showAdditional;
        this.textShowAdditional = this.showAdditional?'Hide Additional':'Show Additional';
    },
    setChanges(value,category){
        if(category.id === 11){
            this.requestData['tags']['Origin'] = value;
        }
    },
    restrictChange($event){
        this.setLastData = false;
    },
    contactChange($event){
        this.$store.dispatch(GET_QUICK_OFFER,$event).then(response => {
                this.latestData = response;
                if(this.setLastData){
                    this.setLatestContactOfferData();
                }else{
                    this.showConfirmSet = true;
                }

            })
    },
    setLatestContactOfferData()
    {
        this.requestData.notes = this.latestData.notes;
        this.requestData.tags = this.latestData.tags;
    },
    accept(e,category) {
    },
    getOpts(opts){
        let options = [];

        Object.keys(opts).forEach(k => {
            if(this.requestData.tags[k] && opts[k][this.requestData.tags[k]]){
                options = options.concat(opts[k][this.requestData.tags[k]]);
            }
        })

        return options;
    }
  },
};
</script>

<style scoped>
.tag:not(body).is-danger {
  background-color: none;
}
</style>
