<template>
    <div>
        <!--Begin::Add REQUEST-->
        <KTPortlet>
            <template v-slot:toolbar></template>
            <template v-slot:body>
                <QOffer
                    :key="offersKey"
                    @reset="offersKey += 1"
                >
                    <template slot="title">{{
                        $t("NEW_QUICK_OFFER.SUB_TITLE")
                    }}</template>
                </QOffer>
            </template>
        </KTPortlet>
        <!--End::Add REQUEST-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import QOffer from "@/views/pages/quickOffer/partials/QuickOffer.vue";

export default {
    name: "NewOffer",
    components: {
        KTPortlet,
        QOffer
    },
    data() {
        return {
            offersKey: 0
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.$t("NEW_QUICK_OFFER.TITLE") }
        ]);
    },
    methods: {}
};
</script>
